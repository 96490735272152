<template>
  <div class="search-steps" data-test="search-steps-container">
    <img
      class="search-steps__main-image"
      data-test="image"
      :src="imgOnboardingIcon"
    >

    <h1 class="search-steps__main-header">
      {{ $t(`${langPath}.title.searchingFor`) }}
      <span class="search-steps__main-header--accent">
        {{ $t(`${langPath}.title.bestTalent`) }}
      </span>
      {{ $t(`${langPath}.title.toYourPosition`) }}
    </h1>

    <div class="search-steps__main-steps">
      <div
        data-test="analysing-profiles"
        class="search-steps__main-steps-step"
      >
        <div class="search-steps__main-steps-step-loader">
          <ev-circle-loader
            v-if="isStep1"
            variant="tertiary"
          />

          <ev-icon
            v-else
            name="check-circle"
            color="red"
          />
        </div>
        <p class="search-steps__main-steps-step-text">
          {{ $t(`${langPath}.analysingProfiles`) }}
        </p>
      </div>

      <div
        data-test="filtering-professionals"
        class="search-steps__main-steps-step hidden-info"
        :class="analysingProfessionalsClasses"
      >
        <div class="search-steps__main-steps-step-loader">
          <ev-circle-loader
            v-if="isStep2"
            variant="tertiary"
          />

          <ev-icon
            v-else
            name="check-circle"
            color="red"
          />
        </div>
        <p class="search-steps__main-steps-step-text">
          {{ $t(`${langPath}.filteringProfessionals`) }}
        </p>
      </div>
      <div
        data-test="selecting-talent"
        class="search-steps__main-steps-step hidden-info"
        :class="selectingTalentClasses"
      >
        <div class="search-steps__main-steps-step-loader">
          <ev-circle-loader
            v-if="isStep3"
            variant="tertiary"
          />

          <ev-icon
            v-else
            name="check-circle"
            color="red"
          />
        </div>

        <p class="search-steps__main-steps-step-text">
          {{ $t(`${langPath}.selectingBestTalent`) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import imgOnboardingIcon from '@/assets/images/onboarding/revelo-onboarding-icon.svg'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import EvIcon from '@revelotech/everestV2/EvIcon'

export default {
  name: 'SearchSteps',
  components: {
    EvCircleLoader,
    EvIcon
  },
  data () {
    return {
      langPath: __langpath,
      step: 1
    }
  },
  computed: {
    imgOnboardingIcon () {
      return imgOnboardingIcon
    },
    isStep1 () {
      return this.step === 1
    },
    isStep2 () {
      return this.step === 2
    },
    isStep3 () {
      return this.step === 3
    },
    analysingProfessionalsClasses () {
      return {
        'search-steps__main-steps-step--hidden': this.step < 2
      }
    },
    selectingTalentClasses () {
      return {
        'search-steps__main-steps-step--hidden': this.step < 3
      }
    }
  },
  mounted () {
    setTimeout(() => { this.step = 2 }, 2000)
    setTimeout(() => { this.step = 3 }, 4000)
    setTimeout(() => { this.step = 4 }, 6000)
    setTimeout(() => { this.$router.push({ name: 'SearchResults' }) }, 7000)
  }
}
</script>

<style lang="scss" scoped>
@import "@revelotech/everestV2/tx_overline.scss";

.search-steps {
  background: var(--bg-neutral-dark-01);
  color: $white;
  height: 100%;
  padding: $base*10;

  &__main {
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      flex-direction: column;
      font: var(--tx-title-1);
      margin-top: $base*10;

      &--accent {
        color: var(--tx-red);
      }
    }

    &-steps {
      margin-top: $base*10;

      &-step {
        display: flex;
        flex-direction: row;
        margin-top: $base*2;

        &-loader {
          width: $base*7;
        }

        &-text {
          margin-left: $base*2;
          margin-top: $base*2;

          @extend %tx-overline;
        }

        &--hidden {
          display: none;
        }
      }
    }
  }
}
</style>
